import ReactDOM from "react-dom/client";
import App from "./component/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import swConfig from './swConfig';
//  document.title = "WEB UI";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onSuccess: (registration) => {
        console.info("service worker on success state");
        console.log(registration);
    },
    onUpdate: (registration) => {
        console.info("service worker is update.");
        registration.unregister().then(() => {
            window.location.reload();
        });
    },
});
