import CryptoJS from "crypto-js";

//  localStorage：設定與讀取token
export const setAuthToken = (token) => {
    localStorage.setItem("token", token);
};
export const getAuthToken = () => {
    return localStorage.getItem("token");
};

//  localStorage：設定與讀取user ID
export const setUser = (user) => {
    localStorage.setItem("user_id", user);
};
export const getUser = () => {
    return localStorage.getItem("user_id");
};

// localStorage：設定與讀取user Role(權限管理)
export const setRole = (role) => {
    localStorage.setItem("user_role", role);
};
export const getRole = () => {
    return localStorage.getItem("user_role");
};

//localStorage：設定與讀取user Name(中文姓名)
export const setName = (name) => {
    localStorage.setItem("user_name", name);
};
export const getName = () => {
    return localStorage.getItem("user_name");
};

//localStorage：設定與讀取user Name(中文姓名)
export const setSite = (site) => {
    localStorage.setItem("user_site", site);
};
export const getSite = () => {
    return localStorage.getItem("user_site");
};

//localStorage：設定與讀取user Name(中文姓名)
export const setSiteName = (site) => {
    localStorage.setItem("site_name", site);
};
export const getSiteName = () => {
    return localStorage.getItem("site_name");
};

//localStorage：設定與讀取不可變動物件multiple(中文姓名)
export const setMultipleSiteRoute = (multiple) => {
    localStorage.setItem("multiple_site_route", multiple);
};
export const getMultipleSiteRoute = () => {
    return localStorage.getItem("multiple_site_route");
};


//localStorage：設定與讀取page
export const setWebPage = (page) => {
    localStorage.setItem("page", page);
};
export const getWebPage = () => {
    return localStorage.getItem("page");
};


/**
 * 解密方法
 * @param data
 * @returns {string}
 */
export function decrypt(data) {
    const SECRET_KEY = CryptoJS.enc.Utf8.parse("3434e6e143439198");
    //加密的偏移量
    const SECRET_IV = CryptoJS.enc.Utf8.parse("e3aae7e3ba84431b");

    const encryptedHexStr = CryptoJS.enc.Hex.parse(data);
    const str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const decrypt = CryptoJS.AES.decrypt(str, SECRET_KEY, {
        iv: SECRET_IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}

export const setRoute = (data) => {
    localStorage.setItem("route_function", data);
};
export const getRoute = () => {
    const a = localStorage.getItem("route_function");
    if (a) {
        return decrypt(a).split(",");
    } else {
        return [];
    }
};

