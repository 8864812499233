import axios from "axios";
import { getAuthToken } from "./utils";

//理論上是用於前後端對接用的切入點，在這個檔案裡定義好路徑與function，方便其他component調用
//理想的前後端分離就是在這邊與後端工程師討論出想要的效果與網址並在此處定義。
// const baseURL = "http://localhost:9000/";
// const baseURL = "http://localhost:5003/";
// 
//正式網站與api
const baseURL = "https://www.emscloud.net/api/";
// const baseURL = "https://www.emscloud.net/demo_api/";
// const baseURL = "http://192.168.1.81:9000/";
// const baseURL = "http://192.168.14.46:5003/";

//測試環境api
// const baseURL = "http://tc.evercomm.com:48103";

// 創建一個 axios 實例，以及將此處定義的實例headers自帶authorization，用於權限檢定
export const request = axios.create({
    baseURL,
    headers: {
        authorization: getAuthToken(), // headers 塞 token
    },
});

// api function
export const getUserTable = (url) => request.get(`/users/${url}`);
export const postUserTable = (url, data) => request.post(`/users/${url}`, data);
export const getLineChart = (url) => request.get(`/chartSearch/${url}`);
export const postLineChart = (url, data) => request.post(`/chartSearch/${url}`, data);
export const getNdhu = (url) => request.get(`/main/${url}`);
export const getNdhumeter = (url) => request.get(`/ndhuMeter/${url}`);
export const postNdhumeter = (url,data) => request.post(`/ndhuMeter/${url}`,data);
export const getStreetlight = (url) => request.get(`/streetlight/${url}`);
export const postStreetlight = (url, data) => request.post(`/streetlight/${url}`, data);
export const getWater = (url) => request.get(`/water/${url}`);
export const getDataRate = (url) => request.get(`/datarate/${url}`);
export const getDeviceStatus = (url) => request.get(`/deviceStatus/${url}`);
export const getControl = (url) => request.get(`/control/${url}`);
export const postControl = (url, data) => request.post(`/control/${url}`, data);
export const getMalfunction = (url) => request.get(`/malfunction/${url}`);
export const postMalfunction = (url, data) => request.post(`/malfunction/${url}`, data);

export const postUserLogin = (url, data) => request.post(`/signin/${url}`, data);
export const getUserLogin = (url) => request.get(`/signin/${url}`);
export const getClassTable = (url) => request.get(`/class/${url}`);
export const postClassTable = (url, data) => request.post(`/class/${url}`, data);
export const getChillerReportTable = (url) => request.get(`/chiller_report/${url}`);
export const postChillerReportTable = (url, data) => request.post(`/chiller_report/${url}`, data);
export const getChillerAnalyze = (url) => request.get(`/analyze/${url}`);
export const postChillerAnalyze = (url, data) => request.post(`/analyze/${url}`, data);
export const getHeatPumpReport = (url) => request.get(`/heatPumpReport/${url}`);
export const postHeatPumpReport = (url, data) => request.post(`/heatPumpReport/${url}`, data);

export const getEss = (url) => request.get(`/ess/${url}`);
