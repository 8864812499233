import { useState, useEffect } from "react";

export default function Clock() {

    let time = new Date().toLocaleTimeString();
    const [atime, setAtime] = useState(time);

    useEffect(() => {
        const tick = () => setAtime(new Date().toLocaleTimeString());
        const timerId = setTimeout(tick, 1000);
        return () => clearTimeout(timerId);
    }, [atime]);

    return (
        <div
            style={{
                fontSize: "14px",
                color: "#666666",
            }}
        >
            {atime}
        </div>
    );
}
