import { useState, useContext } from "react";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SecondaryListItems from "./listitem";
import background from "../../images/hkai.svg";
import { useNavigate } from "react-router-dom";
import useIdleTimeout from "./idleTimer";
import { AuthContext } from "../context";


//重新渲染問題
let sdrawerWidth = document.documentElement.scrollWidth;
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		[theme.breakpoints.down("sm")]: {
			marginLeft: sdrawerWidth,
		},
		width: `calc(100% - ${drawerWidth}px )`,
		[theme.breakpoints.down("sm")]: {
			width: `calc(100% - ${sdrawerWidth}px )`,
		},
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	"& .MuiDrawer-paper": {
		borderRight:'none',
        // borderRight: "1px solid rgba(0, 0, 0, 0.12)", 
		position: "relative",
		whiteSpace: "nowrap",
		width: sdrawerWidth,
		[theme.breakpoints.up("sm")]: {
			width: drawerWidth,
		},
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: "border-box", //左側欄位圖示相關
		...(!open && {
			overflowX: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(0),
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

// const mdTheme = createTheme();

export default function Main(props) {
	const { setPage } = useContext(AuthContext);
	const [open, setOpen] = useState(false);

	const toggleDrawer = () => {
		setOpen(!open);
	};
	const navigate = useNavigate();
	const navigation = (url) => {
		navigate(url);
		if (document.documentElement.scrollWidth <= 500) {
			setOpen(true);
		}
	};

	const logout = () => {
		console.log("User got logged out");
		localStorage.clear();
		setPage(null);
		navigate("/signin");
	};

	const { isIdle } = useIdleTimeout({ onIdle: logout, idleTime: 30 * 60 });


	// useEffect(() => {
	//     //預設開場關閉所側欄位，使用這個保持畫面在550以上時自動開啟
	//     if (document.documentElement.scrollWidth > 599) {
	//         setOpen(!open);
	//     }
	// }, []);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<AppBar position="absolute" open={!open}>
				<Toolbar
					sx={{
						background: `linear-gradient(to bottom,#3c3c3c 0,#222 100%)`,
						boxsShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
						pr: "16px", // keep right padding when drawer closed
					}}
				>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={toggleDrawer}
						sx={{
							marginRight: "36px",
							...(!open && { display: "none" }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<Box
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: {
								xs: "start",
								sm: "space-between",
							},
						}}
					>
						<img
							src={background}
							alt="icons"
							height="60px"
							style={{
								backgroundColor: "#ffffff",
								padding: "3px",
							}}
						/>
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer sx={{ border: "2px", color: "red" }} variant="permanent" open={!open}>
				<Toolbar
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
						zIndex: 7,
						px: [1],
					}}
				>
					<IconButton onClick={toggleDrawer}>
						<ChevronLeftIcon />
					</IconButton>
				</Toolbar>
				<Divider />
				<List component="nav">
					<SecondaryListItems set={props.set} open={open} navigation={navigation} />
				</List>
			</Drawer>
			<Box
				component="main"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
					flexGrow: 1,
					height: "100%",
					width: "100%",
					overflow: "auto",
				}}
			>
				<Toolbar />
				<Container maxWidth="xl" sx={{ py: 4, minHeight: "calc(100vh - 64px)", overflow: "hidden" }}>
					{props.component}
				</Container>
			</Box>
		</Box>
	);
}
