import { useState, useContext } from "react";
import { AuthContext } from "../context";
import { useNavigate } from "react-router-dom";
// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
// import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
// import cookie from "react-cookies";
import Avatar from "react-avatar";
import Clock from "./clock";
import { postUserLogin } from "../api";

export default function App(props) {
    const navigate = useNavigate();
    const { userValue, userName, final_routes, multipleRoute, setMultipleRoute, setPage } =
        useContext(AuthContext);
    // console.log(multipleRoute);

    const logoutAction = () => {
        // cookie.remove("siteId", { path: "/" });
        postUserLogin("logout", { user: userValue })
            .then((res) => {
                console.log(res);
                setMultipleRoute();
                setPage(null)
                localStorage.clear();
                navigate("/signin");
            })
            .catch((err) => console.log(err));
    };

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Box sx={{ display: { xs: "block" }, width: "100%", borderRight: "1px solid rgba(0, 0, 0, 0.12)" }}>
            <ListItemButton onClick={handleClick}>
                <Box>
                    <Box sx={{ display: "flex" }}>
                        <Badge
                            sx={{
                                color: "#929292",
                                "& .MuiBadge-badge": {
                                    // backgroundColor: "#27f54f",
                                    backgroundColor: "#00ff30",
                                },
                            }}
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            variant="dot"
                        >
                            <Avatar name={userName} size="35" round={true} />
                        </Badge>
                        <div
                            style={{
                                width: "100%",
                                marginLeft: "10px",
                                paddingLeft: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <div
                                    style={{
                                        margin: "0 10px",
                                        fontSize: "18px",
                                        color: "#666666",
                                    }}
                                >
                                    {userName}
                                </div>
                                <Clock />
                                <Box
                                    sx={{
                                        color: "#666666",
                                        display: { xs: "block", md: "none" },
                                    }}
                                >
                                    {open ? <ExpandLess /> : <ExpandMore />}
                                </Box>
                            </div>
                        </div>
                    </Box>
                    <Box
                        sx={{
                            color: "#666666",
                            textAlign: "center",
                            display: { xs: "none", md: props.open ? "none" : "block" },
                        }}
                    >
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </Box>
                </Box>
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {/* {["admin", "manager", 'submanager'].includes(userRole) && (
                        <ListItemButton
                            onClick={() => {
                                props.navigation("/userAccount/register/2");
                            }}
                        >
                            <ListItemIcon>
                                <PersonAdd fontSize="small" />
                            </ListItemIcon>
                            Account
                        </ListItemButton>
                    )} */}

                    <ListItemButton
                        onClick={() => {
                            props.navigation("/userAccount/user");
                        }}
                    >
                        <ListItemIcon>
                            <Settings fontSize="small" />
                        </ListItemIcon>
                        設定
                    </ListItemButton>
                    {multipleRoute && (
                        <ListItemButton
                            onClick={() => {
                                props.navigation("/selectSites");
                            }}
                        >
                            <ListItemIcon>
                                <MapsHomeWorkIcon fontSize="small" />
                            </ListItemIcon>
                            選擇案場
                        </ListItemButton>
                    )}

                    {final_routes.find((e) => e.id === 3) && (
                        <ListItemButton
                            onClick={() => {
                                props.navigation("/class_control");
                            }}
                        >
                            <ListItemIcon>
                                <CalendarMonthIcon fontSize="small" />
                            </ListItemIcon>
                            設定課表
                        </ListItemButton>
                    )}
                    {final_routes.find((e) => e.id === 18) && (
                        <ListItemButton
                            onClick={() => {
                                props.navigation("/temp_control");
                            }}
                        >
                            <ListItemIcon>
                                <VideogameAssetIcon fontSize="small" />
                            </ListItemIcon>
                            設定溫度
                        </ListItemButton>
                    )}

                    <ListItemButton onClick={logoutAction}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        登出
                    </ListItemButton>
                </List>
            </Collapse>
            <Divider />
        </Box>
    );
}
